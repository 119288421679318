import styled from 'styled-components';

const StyledContainer = styled.div`
  position: relative;
  padding: 3px;
  background-color: #ece9da;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 600px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #2253de;

  .header__bg {
    background: linear-gradient(
      to bottom,
      #0058ee 0%,
      #3593ff 4%,
      #288eff 6%,
      #127dff 8%,
      #036ffc 10%,
      #0262ee 14%,
      #0057e5 20%,
      #0054e3 24%,
      #0055eb 56%,
      #005bf5 66%,
      #026afe 76%,
      #0062ef 86%,
      #0052d6 92%,
      #0040ab 94%,
      #003092 100%
    );
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 28px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .header__text {
    font-weight: 700;
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif; /* Ensure the font family is Noto Sans */
    color: white;
    text-shadow: 1px 1px #000;
    margin-left: 10px;
  }
  .body__text {
    font-weight: 600;
    font-size: 12px;
    font-family: 'Noto Sans', sans-serif; /* Ensure the font family is Noto Sans */
    color: black;
    
    margin-left: 10px;
  }
`;

export default StyledContainer;
