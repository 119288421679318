import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledLoadingContainer from './StyledContainer'; // Import the styled component

const EnterScreen = ({ onEnter }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);
  const totalBlocks = 24;
  const messages = [
    "downloading your device details...",
    "gathering personal identifiable information...",
    "publishing to the dark web...",
    "accessing bank accounts...",
    "applying for credit cards...",
    "buying bitcoin...",
    "done!"
  ];

  useEffect(() => {
    let messageInterval;
    if (isLoading) {
      messageInterval = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 3000);
    }
    return () => {
      if (messageInterval) clearInterval(messageInterval);
    };
  }, [isLoading, messages.length]);

  const handleEnter = () => {
    setIsLoading(true);
    onEnter();
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          navigate('/home');
          return 100;
        }
        return prevProgress + (100 / totalBlocks);
      });
    }, 800);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <StyledLoadingContainer>
        <div className="header__bg">
          <div className="header__text">ConnorFuckingPower.com</div>
        </div>
        {!isLoading && (
          <>
            <div className="header__text" style={{ 
              marginBottom: '20px', 
              textAlign: 'center', 
              fontSize: '24px',
              fontStyle: 'italic'
            }}>
              welcome
            </div>
            <button
              onClick={handleEnter}
              style={{ padding: '10px 20px', fontSize: '18px', marginBottom: '20px' }}
              className="body__text"
            >
              Start Download
            </button>
          </>
        )}
        {isLoading && (
          <>
            <img 
              src={process.env.PUBLIC_URL + '/file.gif'} 
              alt="Loading animation" 
              style={{ width: '400px', height: '80px', marginBottom: '20px' }}
            />
            <div className="body__text" style={{ marginBottom: '20px' }}>{messages[messageIndex]}</div>
            <div style={{
              width: '80%',
              padding: '10px',
              backgroundColor: 'white',
              border: '1px solid #ccc',
              borderRadius: '8px',
              marginBottom: '10px',
            }}>
              <div style={{ width: '100%', height: '20px', display: 'flex', backgroundColor: '#ffffff' }}>
                {Array.from({ length: totalBlocks }).map((_, index) => (
                  <div
                    key={index}
                    style={{
                      width: `${100 / totalBlocks}%`,
                      height: '100%',
                      borderRadius: '2px',
                      backgroundColor: progress >= ((index + 1) / totalBlocks) * 100 ? '#6ad150' : 'white',
                      marginRight: index < totalBlocks - 1 ? '2px' : '0',
                      transition: 'background-color 0.2s ease-in-out'
                    }}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </StyledLoadingContainer>
    </div>
  );
};

export default EnterScreen;
