// src/components/GlobeScreen.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Globe from 'react-globe.gl';

const GlobeScreen = () => {
  const navigate = useNavigate();
  const globeEl = useRef();
  const [pointsData, setPointsData] = useState([]);
  const [hoveredHex, setHoveredHex] = useState(null);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    // Fetch the photo data from photosData.json
    fetch('/photosData.json')
      .then((response) => response.json())
      .then((photoData) => {
        // Ensure all points have valid lat and lng
        const validPoints = photoData.filter(
          (photo) =>
            typeof photo.lat === 'number' &&
            typeof photo.lng === 'number'
        );
        setPointsData(validPoints);
      })
      .catch((error) => console.error('Error fetching photos data:', error));
  }, []);

  // Define ringsData with a green ring over Dublin, Ireland and a blue ring over Boston, Massachusetts
  const ringsData = [
    {
      lat: 53.3498,       // Latitude for Dublin
      lng: -6.2603,       // Longitude for Dublin
      color: 'green',     // Ring color
      // Optional properties:
      // ringAltitude: 0.1,          // Height above the globe
      // ringMaxRadius: 30,          // Maximum radius before the ring disappears
      // ringPropagationSpeed: 10,   // Speed at which the ring expands (degrees/second)
      // ringRepeatPeriod: 0,        // Time interval between ring generations (0 disables repeats)
    },
    {
      lat: 42.3601,       // Latitude for Boston
      lng: -71.0589,      // Longitude for Boston

      // Optional properties:
      // ringAltitude: 0.1,          // Height above the globe
      // ringMaxRadius: 30,          // Maximum radius before the ring disappears
      // ringPropagationSpeed: 10,   // Speed at which the ring expands (degrees/second)
      // ringRepeatPeriod: 0,        // Time interval between ring generations (0 disables repeats)
    }
  ];

  // Function to compute the center from points
  const computeCenter = (points) => {
    if (!points || points.length === 0) return null;
    const total = points.reduce(
      (acc, point) => ({
        lat: acc.lat + point.lat,
        lng: acc.lng + point.lng,
      }),
      { lat: 0, lng: 0 }
    );
    return {
      lat: total.lat / points.length,
      lng: total.lng / points.length,
    };
  };

  // Function to handle hex click
  const handleHexClick = (hex, event) => {
    console.log('Clicked hex:', hex);
    let lat, lng;

    // Check for 'center' property
    if (hex.center && Array.isArray(hex.center))
      [lat, lng] = hex.center;
    // Fallback to '__hexCenter' if 'center' is undefined
    else if (hex.__hexCenter && Array.isArray(hex.__hexCenter))
      [lat, lng] = hex.__hexCenter;
    // Compute center from points if 'center' properties are missing
    else if (hex.points && hex.points.length > 0) {
      const center = computeCenter(hex.points);
      if (center) {
        lat = center.lat;
        lng = center.lng;
      }
    }

    if (lat !== undefined && lng !== undefined) {
      console.log(`Opening modal with photos at lat=${lat}, lng=${lng}`);
      setModalData({
        lat,
        lng,
        points: hex.points,
      });
      // If you want to keep the navigation, uncomment the next line
      // navigate(`/photos?lat=${lat}&lng=${lng}`);
    } else {
      console.warn('Clicked hex is undefined or missing center:', hex);
    }
  };

  // Function to handle hex hover
  const handleHexHover = (hex, previousHex) => {
    setHoveredHex(hex);
  };

  // Function to close modal
  const closeModal = () => {
    setModalData(null);
  };

  return (
    <div
      style={{
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white',
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        style={{
          padding: '10px 20px',
          fontSize: '18px',
          margin: '20px',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          cursor: 'pointer',
        }}
      >
        Back
      </button>

      {/* Globe */}
      <div style={{ width: '100%', height: '600px' }}>
        <Globe
          ref={globeEl}
          globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
          backgroundColor="black"
          hexBinPointsData={pointsData}
          hexBinPointLat={(d) => d.lat}
          hexBinPointLng={(d) => d.lng}
          hexBinPointWeight={() => 1} // Each photo counts as 1
          hexBinResolution={3} // Set to 3 for thin hexes
          hexMargin={0.005} // Slightly reduced margin for better clickability
          hexAltitude={({ sumWeight }) => sumWeight * 0.015} // Increased altitude for prominence
          hexTopColor={() => 'orange'}
          hexSideColor={() => 'rgba(255, 165, 0, 0.8)'} // Reduced transparency for better visibility
          hexLabel={({ points, sumWeight, center, __hexCenter }) => {
            let lat, lng;

            if (center && Array.isArray(center)) {
              [lat, lng] = center;
            } else if (__hexCenter && Array.isArray(__hexCenter)) {
              [lat, lng] = __hexCenter;
            } else if (points && points.length > 0) {
              const computedCenter = computeCenter(points);
              if (computedCenter) {
                lat = computedCenter.lat;
                lng = computedCenter.lng;
              }
            } else {
              return '';
            }

            return `
              <div style="text-align: center;">
                Lat: ${lat.toFixed(2)}, Lng: ${lng.toFixed(2)}<br/>
                Photos: ${points.length}
              </div>
            `;
          }}
          onHexClick={handleHexClick}
          onHexHover={handleHexHover}
          
          
          ringsData={ringsData}
          ringLat={(d) => d.lat}
          ringLng={(d) => d.lng}
          ringColor={() => 'green'}
          // Optional: Customize ring properties
          // ringAltitude={0.1}
          ringMaxRadius={6}
          // ringPropagationSpeed={10}
          //ringRepeatPeriod={0} // 0 to disable repeating rings
          
          // Adjust camera point of view on globe ready
          onGlobeReady={() =>
            globeEl.current.pointOfView(
              { lat: 0, lng: 0, altitude: 3 }, // Set to 3 for balanced visibility
              0
            )
          }
        />
      </div>

      {/* Hovered Hex Tooltip */}
      {hoveredHex && (
        <div
          style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            padding: '10px 15px',
            borderRadius: '5px',
            pointerEvents: 'none',
            zIndex: 2,
            maxWidth: '250px',
          }}
        >
          <p>
            <strong>Lat:</strong>{' '}
            {(() => {
              if (hoveredHex.center && hoveredHex.center.length === 2)
                return hoveredHex.center[0].toFixed(2);
              if (hoveredHex.__hexCenter && hoveredHex.__hexCenter.length === 2)
                return hoveredHex.__hexCenter[0].toFixed(2);
              if (hoveredHex.points && hoveredHex.points.length > 0) {
                const center = computeCenter(hoveredHex.points);
                if (center) return center.lat.toFixed(2);
              }
              return 'N/A';
            })()} <br />
            <strong>Lng:</strong>{' '}
            {(() => {
              if (hoveredHex.center && hoveredHex.center.length === 2)
                return hoveredHex.center[1].toFixed(2);
              if (hoveredHex.__hexCenter && hoveredHex.__hexCenter.length === 2)
                return hoveredHex.__hexCenter[1].toFixed(2);
              if (hoveredHex.points && hoveredHex.points.length > 0) {
                const center = computeCenter(hoveredHex.points);
                if (center) return center.lng.toFixed(2);
              }
              return 'N/A';
            })()} <br />
          </p>
        </div>
      )}

      {/* Modal */}
      {modalData && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 3,
          }}
          onClick={closeModal} // Close modal when clicking outside the content
        >
          <div
            style={{
              position: 'relative',
              width: '90%',
              maxWidth: '900px', // Increased maxWidth for larger images
              maxHeight: '90%',
              backgroundColor: '#1e1e1e',
              padding: '20px',
              borderRadius: '8px',
              overflowY: 'auto',
              color: 'white',
              fontFamily: 'Arial, sans-serif',
            }}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the content
          >
            {/* Close Button */}
            <button
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                color: 'white',
                fontSize: '24px',
                cursor: 'pointer',
              }}
            >
              &times;
            </button>
            {/* Modal Header */}
            {modalData.lat && modalData.lng && (
              <div style={{ marginBottom: '20px' }}>
                <h2>
                  {modalData.lat.toFixed(2)}, {modalData.lng.toFixed(2)}
                </h2>
              </div>
            )}
            {/* Photos Display */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              {modalData.points.map((photo, index) => (
                <div key={index} style={{ textAlign: 'center' }}>
                  <img
                    src={`/photos/${photo.fileName}`}
                    alt={photo.details}
                    loading="lazy"
                    style={{
                      width: '100%',
                      maxWidth: '800px', // Increased maxWidth for larger images
                      borderRadius: '8px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    }}
                  />
                  <p style={{ marginTop: '10px', fontSize: '16px' }}>{photo.details}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobeScreen;
