// src/components/PhotosScreen.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

const PhotosScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [photos, setPhotos] = useState([]);
  const [locationInfo, setLocationInfo] = useState({ lat: null, lng: null });

  useEffect(() => {
    // Parse query parameters
    const { lat, lng } = queryString.parse(location.search);
    if (lat && lng) {
      setLocationInfo({ lat: parseFloat(lat), lng: parseFloat(lng) });
      fetchPhotos(parseFloat(lat), parseFloat(lng));
    } else {
      console.error('Latitude and Longitude are required.');
    }
  }, [location.search]);

  const fetchPhotos = async (lat, lng) => {
    try {
      const response = await fetch('/photosData.json');
      const photoData = await response.json();
      // Filter photos matching the provided lat and lng (with some tolerance)
      const tolerance = 0.0001; // Adjust as needed
      const matchingPhotos = photoData.filter(
        (photo) =>
          Math.abs(photo.lat - lat) < tolerance &&
          Math.abs(photo.lng - lng) < tolerance
      );
      setPhotos(matchingPhotos);
    } catch (error) {
      console.error('Error fetching photos data:', error);
    }
  };

  return (
    <div
      style={{
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white',
        minHeight: '100vh',
        padding: '20px',
        position: 'relative',
      }}
    >
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        style={{
          padding: '10px 20px',
          fontSize: '18px',
          marginBottom: '20px',
          cursor: 'pointer',
        }}
      >
        Back
      </button>

      {/* Location Info */}
      {locationInfo.lat && locationInfo.lng && (
        <div style={{ marginBottom: '20px' }}>
          <h2>
            Photos at Latitude {locationInfo.lat.toFixed(4)}, Longitude{' '}
            {locationInfo.lng.toFixed(4)}
          </h2>
        </div>
      )}

      {/* Photos Grid */}
      {photos.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '15px',
            justifyContent: 'center',
          }}
        >
          {photos.map((photo, index) => (
            <div key={index} style={{ width: '250px' }}>
              <img
                src={`/photos/${photo.fileName}`}
                alt={photo.details}
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '5px',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.5)',
                }}
              />
              <p style={{ marginTop: '10px' }}>{photo.details}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No photos available for this location.</p>
      )}
    </div>
  );
};

export default PhotosScreen;
