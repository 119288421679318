import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CameraViewer from './CameraViewer';

const HomeScreen = ({ model, modelLoaded }) => {
  const [currentDate, setCurrentDate] = useState('');
  const [growingNumber, setGrowingNumber] = useState(35767069768000);
  const [activeItem, setActiveItem] = useState(null);
  const [bitcoinPrice, setBitcoinPrice] = useState('Loading...');
  const navigate = useNavigate();

  const topRightItems = [
    currentDate,
    { value: `$${growingNumber.toLocaleString()}`, link: 'https://www.usdebtclock.org/' },
    { value: bitcoinPrice, link: 'https://www.coindesk.com/price/bitcoin' },
  ];

  const bottomLeftItems = [
    { title: 'Who', content: 'me' },
    {
      title: 'What',
      content: [
        { name: 'Work', link: 'https://www.usdebtclock.org/' },
        { name: 'Writing', route: '/writing' },
      ],
    },
    {
      title: 'Where',
      content: [
        { name: 'Globe', route: '/globe' },
      ],
    },
  ];

  useEffect(() => {
    // Update current date
    const date = new Date();
    const formattedDate = date
      .toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
      .replace(/\//g, '.');
    setCurrentDate(formattedDate);

    // Update growing number every 100ms
    const intervalId = setInterval(() => {
      setGrowingNumber((prevNumber) => prevNumber + 16667);
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchBitcoinPrice = async () => {
      try {
        const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice/USD.json');
        const data = await response.json();
        const price = Number(data.bpi.USD.rate.replace(/[^0-9.-]+/g, ''));
        setBitcoinPrice(`$${price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`);
      } catch (error) {
        console.error('Error fetching Bitcoin price:', error);
        setBitcoinPrice('Error');
      }
    };

    fetchBitcoinPrice();
    const intervalId = setInterval(fetchBitcoinPrice, 2000); // Update every 2 seconds

    return () => clearInterval(intervalId);
  }, []);

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        padding: '20px',
        backgroundColor: 'black',
        overflow: 'hidden',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      }}
    >
      {/* Top right container */}
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          textAlign: 'right',
          color: 'white',
        }}
      >
        <ul style={{ listStyleType: 'none', padding: 10, marginRight: 40 }}>
          {topRightItems.map((item, index) => (
            <li
              key={index}
              style={{
                marginBottom: 5,
                cursor: item.link ? 'pointer' : 'default',
                userSelect: 'none',
              }}
              onClick={() =>
                item.link && window.open(item.link, '_blank', 'noopener,noreferrer')
              }
            >
              {item.value || item}
            </li>
          ))}
        </ul>
      </div>

      {/* Centered CameraViewer */}
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <CameraViewer model={model} modelLoaded={modelLoaded} />
      </div>

      {/* Bottom left container */}
      <div
        style={{
          position: 'absolute',
          bottom: 160,
          left: 10,
          color: 'white',
          textAlign: 'left',
        }}
      >
        {bottomLeftItems.map((item, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <div
              onClick={() => setActiveItem(activeItem === index ? null : index)}
              style={{
                cursor: 'pointer',
                color: activeItem === index ? '#ADD8E6' : 'white',
                padding: '5px 10px',
                userSelect: 'none',
              }}
            >
              {item.title}
            </div>
            {activeItem === index && (
              <div
                style={{
                  marginLeft: '20px',
                  marginTop: '5px',
                  color: '#D3D3D3',
                }}
              >
                {Array.isArray(item.content) ? (
                  item.content.map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      onClick={() => handleNavigation(subItem.route)}
                      style={{
                        cursor: 'pointer',
                        color: '#ADD8E6',
                        padding: '5px 10px',
                        userSelect: 'none',
                      }}
                    >
                      {subItem.name}
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      paddingLeft: '10px',
                      color: '#D3D3D3',
                      userSelect: 'none',
                    }}
                  >
                    {item.content}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeScreen;
