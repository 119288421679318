import React from 'react';
import { useNavigate } from 'react-router-dom';

const GridScreen = () => {
  const navigate = useNavigate();

  return (
    <div style={{ color: 'white', textAlign: 'center', padding: '20px' }}>
      <h1>Grid Screen</h1>
      <p>This is the Grid Screen. You can display grid-related content here.</p>
      <button 
        onClick={() => navigate(-1)} 
        style={{ padding: '10px 20px', fontSize: '18px', marginTop: '20px' }}
      >
        Back
      </button>
    </div>
  );
};

export default GridScreen;
